<template>
  <v-row justify="center">
    <v-col cols="12">
      <FinalizePasswordResetForm :token="token" />
    </v-col>
  </v-row>
</template>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import FinalizePasswordResetForm from '@/pages/security/Form/FinalizePasswordResetForm.vue';

export default {
  components: { FinalizePasswordResetForm },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  apollo: {
    user: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
};
</script>
