<template>
  <v-row justify="center">
    <v-col lg="4" xl="4" md="4" sm="8" xs="12">
      <FinalizeRegistrationForm :token="token" />
    </v-col>
  </v-row>
</template>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import FinalizeRegistrationForm from '@/pages/security/Form/FinalizeRegistrationForm.vue';

export default {
  components: { FinalizeRegistrationForm },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  apollo: {
    user: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
};
</script>
