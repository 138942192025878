<template>
  <span>
    <VTextField
      outlined
      v-model="myInternalValue"
      type="password"
      :error="shouldValidate && errorBucket.length > 0"
      label="hasło"
    />
    <VTextField outlined v-model="passwordRepeat" type="password" :rules="[passwordsMatch]" label="powtórz hasło" />
    <VMessages v-if="shouldValidate" :value="errorBucket" color="error" />
  </span>
</template>

<script>
import { VInput, VMessages, VTextField } from 'vuetify/lib';

export default {
  name: 'PasswordRepeatField',
  extends: VInput,
  props: {
    value: String,
    rules: Array,
  },
  components: {
    VTextField,
    VMessages,
  },
  data() {
    return {
      passwordRepeat: null,
    };
  },
  computed: {
    passwordsMatch() {
      if (!this.myInternalValue || this.myInternalValue !== this.passwordRepeat) {
        return 'hasła różnią się';
      }
      return true;
    },
    myInternalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (this.passwordsMatch !== true) {
          this.$emit('input', null);
        }
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
