<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Reset hasła</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <AbstractForm
          ref="form"
          :mutation="MUTATION"
          :mutation-variables="(data) => data"
          :mutation-error-mapper="(data) => (data.data.user.errors ? data.data.user.errors : [])"
          :form-specification="formSpecification"
          @updateStore="onDone"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit" :loading="loading">Dalej</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { VTextField } from 'vuetify/lib';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import { emailValidator, isRequiredValidator } from '@/helpers/validators';
import { ForgotPasswordDocument } from '@/generated/operations';

export default {
  components: { AbstractForm },
  data() {
    return {
      MUTATION: ForgotPasswordDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'email' },
                props: { 'label': 'Login', 'prepend-icon': 'mdi-account-outline' },
                rules: [isRequiredValidator, emailValidator],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    async onDone() {
      // eslint-disable-next-line no-alert
      alert('Sprawdź pocztę e-mail');
    },
    async submit() {
      this.$refs.form.mutate();
    },
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
  },
};
</script>
