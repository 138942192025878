<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar dark color="secondary">
        <v-toolbar-title>Dokończ rejestrację</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <AbstractForm
          ref="form"
          :form-specification="formSpecification"
          :mutation="MUTATION"
          :mutation-error-mapper="(data) => (data.data.auth.errors ? data.data.auth.errors : [])"
          :mutation-variables="
            (data) => {
              data.token = token;
              return { input: data };
            }
          "
          @updateStore="onDone"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="send()">Zapisz</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { isRequiredValidator } from '@/helpers/validators';
import { onLogin } from '@/plugins/apollo';
import AbstractForm from '@/components/Form/AbstractForm.vue';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import MUTATION from '@/graphql/security/mutation/FinalizeRegistrationMutation.gql';
import PasswordRepeatField from '@/components/FormFields/Security/PasswordRepeatField.vue';

export default {
  name: 'FinalizeRegistrationForm',
  components: { AbstractForm },
  data() {
    return {
      loading: false,
      MUTATION,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: PasswordRepeatField,
                model: { out: 'password' },
                props: { label: 'Hasło', type: 'password' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onDone({ serverData: { data, errors } }) {
      if (typeof errors !== 'undefined') {
        // TODO handle server error here ? Or in AbstractForm ?
        return;
      }
      if (typeof data.auth.errors !== 'undefined') {
        console.info('Not handling form, form validation error occurred');
        // form had some errors, ignore. AbstractForm is handling those errors
        return;
      }
      const apolloClient = this.$apollo.provider.defaultClient;
      // store token in local storage for later use
      await onLogin(apolloClient, data.auth.token.value, data.auth.refreshToken.value);
      apolloClient.writeQuery({
        query: CURRENT_USER,
        data: {
          user: data.auth.user,
        },
      });
      this.$router.push({ name: 'home' });
    },
    send() {
      this.$refs.form.mutate();
    },
  },
};
</script>
